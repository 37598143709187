import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"
import "./post.css"
export default ({ data }) => {
    const post = data.markdownRemark
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{post.frontmatter.title} - George Nance</title>
          <meta name="description" content={post.excerpt}/>
          <meta name="og:title" property="og:title" content={post.frontmatter.title+` - George Nance`}/>
        </Helmet>
      <article>
        <section className="postHead">
        <h1>{post.frontmatter.title}</h1>
        <p>{post.frontmatter.date}</p>
        </section>
        <hr></hr>
        <div className="postBody" dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      excerpt
    }
  }
`